// JEWEILIGE KOMPONENTEN DER SEITEN DER FAIRMÖGENSKOMPASS-WEBSITE
const homeComponent = () => import('@/components/pages/Home.vue');
const privateCustomersComponent = () => import('@/components/pages/PrivateCustomers.vue');
const b2bComponent = () => import('@/components/pages/PageB2B.vue');
const NotFoundComponent = () => import('@/components/pages/NotFoundComponent.vue');
const Imprint = () => import('@/components/pages/Imprint.vue');
const PrivacyPolicy = () => import('@/components/pages/PrivacyPolicy.vue');

// ICONS FÜR DIE INFOBOXEN DER WEBSITE

export default {
// SEITEN DER FAIRMÖGENSKOMPASS-WEBSITE
  "pages": [
    { // Seite: Not Found (Wird geladen, wenn die Seite nicht gefunden wird)
      "id": "not-found",
      "path": "/error",
      "name": "Error",
      "component": NotFoundComponent,
      "label": "Seite nicht gefunden",
      "inHeader": false,
      "pageColor": 'red',
    },
    { // Seite: Homepage
      "id": "home",
      "path": "/",
      "name": "homepage",
      "component": homeComponent,
      "label": "Startseite",
      "inHeader": true,
      "pageColor": '#011312',
      "sections": [
        {
          "id": "misson",
          "navText": "Finanzdienstleistung für Privatkunden",
          "label": "Finanzdienstleistung für Privatkunden",
          "content": " Fairmögenskompass für individuelle Finanzlösungen Erhalte ein maßgeschneidertes Fairmögenskonzept, das von der Existenzabsicherung bis zur umfassenden Vermögensplanung reicht. Vertraue auf unsere langjährige Erfahrung und Expertise für eine ganzheitliche Finanzberatung.",
          "li1": "Transparenz",
          "li2": "Qualität",
          "li3": "Sicherheit",
        },
        {
          "id": "fairmögenskompass",
          "navText": "Unser Kompass",
          "label": "Unser Fairmögenskompass",
          "content": 'Mit unserem "Fairmögenskompass" navigieren wir dich nicht nur durch den Finanzdschungel, sondern auch durch eine faire und transparente Beratung. Wir setzen den Kurs für deine finanzielle Sicherheit und begleiten dich mit fairen Lösungen auf deinem Weg zu einem gut geschützten Vermögen. Auf uns kannst du dich verlassen, dennunsere oberste Priorität ist deine Zufriedenheit.',
          "image": "@/assets/images/beratungsraum-blur.jpg",
          "alt": "Bild eines Kompasses",
        },
        {
          "id": "buissness-areas",
          "navText": "Geschäftsfelder",
          "label": "Geschäftsfelder",
          "heading_0": "Bildungsträger\n",
          "content_0": "Unsere Expertise als Wegweiser in der Versicherungs- & Finanzbranche:\n\nEntdecke deine beruflichen Perspektiven mit unseren staatlich zertifizierten Bildungsprogrammen. Als anerkannter Bildungsträger unterstützen wir Brancheneinsteiger auf ihrem Weg in die Versicherungs- & Finanzbranche. Auch Brancheninsider profitieren von geförderten Weiterbildungen, um ihre Marketingprozesse, Vertriebsansätze und fachlichen Kompetenzen zu erweitern.\n\n",
          "heading_1": "Unternehmensberatung\n",
          "content_1": "Fairmögenskompass für unternehmerische Gestaltung:\n\nNutze unseren Fairmögenskompass, um dein Unternehmen strategisch smart zu gestalten. Wir bieten maßgeschneiderte Beratungsdienstleistungen, die sich unter anderem auf das Finden, Binden und langfristige Sichern von Mitarbeitern konzentrieren. Unser Fokus liegt dabei auf der betrieblichen Altersvorsorge und betrieblichen Krankenversicherung, mit denen du deinen Mitarbeitern glücklich machen kannst. Lass uns gemeinsam dein Unternehmen wirtschaftlich und steuerlich optimal aufstellen.",
          "heading_2": "Finanzdienstleistung für Privatkunden\n",
          "content_2": "Fairmögenskonzepte für individuelle Finanzlösungen\n\nErhalte ein maßgeschneidertes Fairmögenskonzept, das von der Existenzabsicherung bis zur umfassenden Vermögensplanung reicht. Unser Leistungsspektrum umfasst Rentenversicherungen, Kapitalanlagen und vieles mehr. Vertraue auf unsere langjährige Erfahrung und Expertise für eine ganzheitliche Finanzberatung.",
        },
        {
          "id": "team",
          "navText": "Unser Team",
          "label": "Lerne das Team hinter FMK kennen!",
          "content": "Gemeinsam für deinen Erfolg",
        },
        {
          "id": "accordion-values",
          "navText": "Unsere Werte",
          "label": "Die Werte von Fairmögenskompass",
          "accordionSections": [
            {
              "heading": "1. Vertrauen",
              "content": "Wir legen höchsten Wert auf Vertrauen, das durch Transparenz, Ehrlichkeit und Zuverlässigkeit geschaffen wird. Deine finanziellen Belange sind bei uns in vertrauensvollen Händen und oberste Priorität.",
              "backgroundColor":"#496D6A",
            },
            {
              "heading": "2. Fairness",
              "content": "Fairness ist unser Leitprinzip. In jeder Beratung und Dienstleistung streben wir nach fairen Lösungen, die deinen individuellen Bedürfnissen gerecht werden. Fairmögenskompass steht für einen seriösen, offenen Umgang und gerechte Konditionen.",
              "backgroundColor":"#8C7567",
            },
            {
              "heading": "3. Nachhaltigkeit",
              "content": "Wir verfolgen eine nachhaltige Ausrichtung in all unseren Handlungen. Langfristige, sichere Lösungen stehen im Fokus unserer Dienstleistungen. Wir setzen auf eine nachhaltige Gestaltung deiner finanziellen Zukunft und streben eine langfristige Zusammenarbeit an.",
              "backgroundColor":"#657050",
            },
          ],
        },
      ]
    },

    { // Seite: Privatkunden
      "id": "private-customers-page",
      "path": "/privatkunden",
      "name": "Leistung für Privatkunden",
      "component": privateCustomersComponent,
      "label": "Privatkunden",
      "inHeader": true,
      "pageColor": '#b0a768',
      "sections": [
        {
          "id": "Überschrift 1",
          "label": "",
          "content": ""
        },
        {
          "id": "accordion-q&a-private-customers-page",
          "navText": "Q&A",
          "label": "Q&A",
          "accordionSections": [
            {
              "heading": "Wie viel kostet die Beratung",
              "content": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            },
            {
              "heading": "Wie läuft die Beratung ab?",
              "content": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            },
            {
              "heading": "Warum Fairmögenskompass",
              "content": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            },
          ],
        },
      ]
    },
    { // Seite: B2B
      "id": "unternehmensberatung-page",
      "path": "/unternehmensberatung",
      "name": "Unternehmensberatung",
      "component": b2bComponent,
      "label": "Unternehmensberatung",
      "inHeader": false,
      "pageColor": '#65774D',
      "sections": [
        {
          "id": "Überschrift 1",
          "label": "",
          "content": ""
        },
      ]
    },
    { // Seite: Maklerkonzepte
      "id": "bildungsträger",
      "path": "/bildungstraeger",
      "name": "Bildungsträger",
      "component": NotFoundComponent,
      "label": "Bildungsträger",
      "inHeader": false,
      "pageColor": "#e4ebec",
      "pageFontColor": "#1b2f2b",
      "sections": [
        {
          "id": "Überschrift 1",
          "label": "",
          "content": ""
        },
      ]
    },
    { // Seite: Impressum
      "id": "imprint-page",
      "path": "/impressum",
      "name": "Impressum",
      "component": Imprint,
      "label": "Impressum",
      "inHeader": false,
      "pageColor": '#b0a768',
      "sections": [

      ]
    },
    { // Seite: Datenschutzerklärung
      "id": "privacy-policy-page",
      "path": "/datenschutz",
      "name": "Datenschutzerklärung",
      "component": PrivacyPolicy,
      "label": "Datenschutzerklärung",
      "inHeader": false,
      "pageColor": '#b0a768',
      "sections": [

      ]
    },

  ],
  
// SOCIAL MEDIA BUTTONS
// Die Buttons im Footer. Alle in dieser Array werden in den Footer geladen.
// Die Icons findest du auf fontawesome, z.B. da: https://fontawesome.com/v4/icon/facebook
// Du musst nur die Klassennamen des icons (z.B."fa-brands fa-square-facebook" unter fa-classes eintragen, um das Icon zu laden)
"social-media": [
  {
    "id": "socia-media-1",
    "name": "Instagram",
    "label": "Instagram",
    "link": "https://www.instagram.com/fairmoegenskompass.ifug/",
    "fa-classes": "fa-brands fa-instagram"
  },
  {
    "id": "socia-media-3",
    "name": "Facebook",
    "label": "Facebook",
    "link": "https://www.facebook.com/",
    "fa-classes": "fa-brands fa-square-facebook"
  },
],

// INFO-BOXEN
// Boxen an verschiedenen Stellen der Website. Die Komponente braucht nur die ID, der Rest wird dann von hier aus geladen
"info-boxes": [
  {
    "id": "box-in-progress",
    "colorMode": "light", // light oder dark (leer lassen für light)
    "label": "Website in Arbeit", //Keine Doppelpunkte verwenden, wird automatisch in Großbuchstaben gebracht
    "infoText": "Unsere Website ist monentan noch in Arbeit. Wir setzten alles daran, dir in Zukunft eine tolle Seite zu präsentieren!",
    "svgPath": "website-icon.svg", // Muss in src\assets\svgs eingebunden sein, benötigt korrekten File-Namen, SVG darf keine height-, width- oder fill-Propertys haben
    "alt": "Icon einer Website" // Bild kurz beschreiben
  },
  {
    "id": "box-not-found",
    "colorMode": "dark", // light oder dark (leer lassen für light)
    "label": "Fehler", //Keine Doppelpunkte verwenden, wird automatisch in Großbuchstaben gebracht
    "infoText": "Die Seite wurde leider nicht gefunden, sieht so aus, als würde sie momentan noch nicht fertig sein. Unsere Website wird momentan noch erstellt - und wir probieren, sie so schnell, wie möglich mit Content zu füllen.",
    "svgPath": "not-found-icon.svg", // Muss in src\assets\svgs eingebunden sein, benötigt korrekten File-Namen, SVG darf keine height-, width- oder fill-Propertys haben
    "alt": "Icon einer Website" // Bild kurz beschreiben
  },
],
// BANNER
// Banner an verschiedenen Stellen der Website.
"banners": [
  {
    "id": "banner-home",
    "page-id": "home",
    "bannerText": "Dein Wegweiser für finanzielle Klarheit und Struktur!",
    "image": "leaves-banner-home.png",
    "alt": "Banner mit Blättern", // Bild kurz beschreiben
  },
  {
    "id": "banner-not-found",
    "page-id": "not-found",
    "bannerText": "404 - Seite nicht gefunden",
    "image": "not-found-banner.png",
    "alt": "Banner der Fehlermeldungsseite",
  },

],
// MITARBEITER
// Alle Mitarbeiter, die auf der FMK-Website gezeigt werden
"members": [
  {
    "id": "member-katha",
    "member-id": "katha",
    "memberName": "Katharina\nKarageorgos",
    "memberRole": "Geschäftsführung",
    "image": "member-katharina-karageorgos.png",
    "alt": "Bild von Katharina",
  },
  {
    "id": "member-niklas",
    "member-id": "niklas",
    "memberName": "Niklas\nFriedenreich",
    "memberRole": "Geschäftsführung",
    "image": "member-niklas-friedenreich.png",
    "alt": "Bild von Niklas",
  },
  {
    "id": "member-anika",
    "member-id": "Anika",
    "memberName": "Anika\nMäder",
    "memberRole": "Assistentin der\nGeschäftsführung",
    "image": "member-anika-mäder.png",
    "alt": "Bild von Anika",
  },
  {
    "id": "member-chris",
    "member-id": "Chris",
    "memberName": "Chris\nSchalk",
    "memberRole": "Head of \nMarketing",
    "image": "member-chris-schalk.png",
    "alt": "Bild von Chris",
  },
  {
    "id": "member-nena",
    "member-id": "Nena",
    "memberName": "Nena\nSeidemann",
    "memberRole": "Expertin für\nInvestments und\nAltersvorsorge",
    "image": "member-nena-seidemann.png",
    "alt": "Bild von Nena",
  },
  {
    "id": "member-anna",
    "member-id": "Anna",
    "memberName": "Anna\nMorosow",
    "memberRole": "Expertin für\nAbsicherung und\nVorsorge",
    "image": "member-anna-morosow.png",
    "alt": "Bild von Anna",
  },
  {
    "id": "member-kevin",
    "member-id": "Kevin",
    "memberName": "Kevin\nDudek",
    "memberRole": "Experte für\nFinanzierung",
    "image": "member-kevin-dudek.png",
    "alt": "Bild von Kevin",
  },
],
// Bilder
// Alle Bilder für die Image-Sektionen
"images": [
  {
    "id": "image-compass",
    "image": "large-compass-banner.png",
    "alt": "Bild von Katharina",
  },
]
}
